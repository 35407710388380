import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { support as supportData } from 'app/mock-api/dashboards/support/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class SupportMockApi {
    private _support: any[] = supportData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Support - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/dashboards/support')
            .reply(() => {
                // Clone the Appointment
                const support = cloneDeep(this._support);

                // Sort the Appointment alphabetically by id
                support.sort((a, b) => a.id.localeCompare(b.id));

                return [200, support];
            });
    }
}
