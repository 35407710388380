import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { Alert, AlertService } from './core/alert';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, FuseAlertComponent],
})
export class AppComponent implements OnInit {
    showAlert: any;
    alert: any;
    /**
     * Constructor
     */
    constructor(private _alertService: AlertService, private _changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        // Get the alert state
        this._alertService.alert$
            .subscribe((alertDetails: Alert) => {
                this.alert = alertDetails.alertData;
                this.showAlert = alertDetails.alertState;
                this._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.dismissAlert();
                }, 3000);
            });
    }

    // Method to dismiss fuse alert
    dismissAlert() {
        this.showAlert = false;
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }
}
