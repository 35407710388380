import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class IconsService
{
    /**
     * Constructor
     */
    constructor()
    {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        // Register icon sets
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        matIconRegistry.addSvgIconSetInNamespace('mat_outline', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        matIconRegistry.addSvgIconSetInNamespace('mat_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        matIconRegistry.addSvgIconSetInNamespace('feather', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-mini.svg'));
        matIconRegistry.addSvgIcon('web', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/web.svg'));
        matIconRegistry.addSvgIcon('history', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/history.svg'));
        matIconRegistry.addSvgIcon('key-1', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/key-1.svg'));
        matIconRegistry.addSvgIcon('google', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/google-icon.svg'));
        matIconRegistry.addSvgIcon('microsoft', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/microsoft-icon.svg'));
        matIconRegistry.addSvgIcon('brand-icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/brand-icon.svg'));
        matIconRegistry.addSvgIcon('female-icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/female_logo.svg'));




    }
}
