import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { billing as billingData } from 'app/mock-api/dashboards/billing/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class BillingMockApi {
    private _billing: any[] = billingData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Support - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/dashboards/billing')
            .reply(() => {
                // Clone the Appointment
                const billing = cloneDeep(this._billing);

                // Sort the Appointment alphabetically by id
                billing.sort((a, b) => a.id.localeCompare(b.id));

                return [200, billing];
            });
    }
}
