export const billing = [
    {   id:'1',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'2',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'3',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'4',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'5',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'6',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'7',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'8',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'9',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'10',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'11',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'12',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    },
    {
        id:'13',
        amount: '500',
        invoice_number: '438493740-473',
        billing_date: 'May 22,2024',
        billing_service: 'Follow-up X-ray to assess healing progression or disease progression.',
        status: 1
    }
]