export const appointment = [
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 2,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 1,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }, {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        resourceId: 1,
        service: {
            id: 1,
            title: 'test service',
            color: 'red',
            visible: true,
        },
        type: 1,
        status: 3,
        amount: 1,
        mode: 'test',
        email: 'test@yopmail.com',
        phone: '1234567890',
        recurringEventId: '1',
        isFirstInstance: true,
        title: 'Test Appointment',
        notes: 'Test Note',
        start: '12/12/2024',
        end: '13/12/2024',
        allDay: true,
        recurrence: 'test',
        check_in: '12/12/2024',
        transaction_total: 1,
        mrn: 'test',
        assigned_to: 'Test User',
        booked_by: 'Test User',
    }
]