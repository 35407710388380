export const support = [
    {
        id: '983745102',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 1
    },
    {
        id: '182934567',
        subject: 'Changed appointment',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 2
    },
    {
        id: '435493740',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 3
    },
    {
        id: '237865409',
        subject: 'Changed appointment',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 1
    },
    {
        id: '809173254',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 2
    },
    {
        id: '450378192',
        subject: 'Changed appointment',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 3
    },
    {
        id: '450378192',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 1
    },
    {
        id: '450378192',
        subject: 'Changed appointment',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 2
    },
    {
        id: '450378192',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 3
    },
    {
        id: '450378192',
        subject: 'Changed appointment',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 1
    },
    {
        id: '450378192',
        subject: 'No confirmation 2nd opinion',
        created_at: 'May 22,2024',
        last_activity: '2 days ago',
        status: 2
    }
]