export const exam = [
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 2,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 3,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 4,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    }, {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        checked: true,
        patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        patient: {
            patient_id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
            sex: 'male',
            name: 'Test patient',
            study_notes: 'Test study notes',
            scan_id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
            cardiac_history: 'none',
            blood_pressure: '70',
            birth_date: '12/06/1998',
            cholesterol: '120',
            diabetes: '100',
            email: 'patient@yopmail.com',
            ethnicity: 'Asian',
            family_cardiac_history: 'none',
            height: '6',
            medications: 'none',
            smoking: 'yes',
            smoking_history: 'yes',
            previous_calcium_score: '100',
            age: '26',
            phone: '1234567890',
            weight: '60',
            scored_by: 'Test Doctor',
            surgery_details: 'none',
        },
        department: {
            department_name: 'Test Dept',
            department_code: 'A001',
        },
        referrer: {
            first_name: 'Tanmoy',
            last_name: 'Manna',
        },
        assigned_to: {
            first_name: 'Tanmoy1',
            last_name: 'Manna',
            id: 6,
        },
        qa: {
            first_name: 'Tanmoy2',
            last_name: 'Manna',
            id: 5,
        },
        reader: {
            first_name: 'Tanmoy3',
            last_name: 'Manna',
            id: 7,
        },
        modality_id: 'test',
        scan_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        exam_assign_date: '12/12/2024',
        exam_finalized_date: '13/12/2024',
        exam_date: '14/12/2024',
        exam_report: 'Good',
        exam_notes: 'Test Notes',
        end_time: '15/12/2024',
        created_at: '10/12/2024',
        scored_by: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        pdf_url: 'www.cacius.com',
        audio_url: 'www.cacius.com',
        status: 1,
        urgent: 1,
        exam_status: 1,
        image_status: 1,
        missing_images: false,
        reports: [{
            report_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            modality_id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
            exam_date: '12/12/2024',
            pdf_url: 'www.cacius.com',
        }],
        images: [],
        exam_files: [],
        history: [],
    }
]