import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { exam as examData } from 'app/mock-api/dashboards/exam/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ExamMockApi {
    private _exam: any[] = examData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Exam - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/dashboards/exam')
            .reply(() => {
                // Clone the Exam
                const exam = cloneDeep(this._exam);
                // Sort the Exam alphabetically by idd
                exam.sort((a, b) => a.id.localeCompare(b.id));

                return [200, exam];
            });
    }
}
