import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { appointment as appointmentData } from 'app/mock-api/dashboards/appiontment/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AppointmentMockApi {
    private _appointment: any[] = appointmentData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Appointment - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/dashboards/appointment')
            .reply(() => {
                // Clone the Appointment
                const appointment = cloneDeep(this._appointment);

                // Sort the Appointment alphabetically by id
                appointment.sort((a, b) => a.id.localeCompare(b.id));

                return [200, appointment];
            });
    }
}
