import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { categories as categoriesData, workspaces as workspacesData, demoCourseSteps as demoCourseStepsData } from 'app/mock-api/apps/academy/data';
import { cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class AcademyMockApi
{
    private _categories: any[] = categoriesData;
    private _workspaces: any[] = workspacesData;
    private _demoCourseSteps: any[] = demoCourseStepsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Categories - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/categories')
            .reply(() =>
            {
                // Clone the categories
                const categories = cloneDeep(this._categories);

                // Sort the categories alphabetically by title
                categories.sort((a, b) => a.title.localeCompare(b.title));

                return [200, categories];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Courses - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/workspaces')
            .reply(() =>
            {
                // Clone the workspaces
                const workspaces = cloneDeep(this._workspaces);

                return [200, workspaces];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Workspace - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/academy/workspaces/workspace')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the workspaces and steps
                const workspaces = cloneDeep(this._workspaces);
                const steps = cloneDeep(this._demoCourseSteps);

                // Find the workspace and attach steps to it
                const workspace = workspaces.find(item => item.id === id);
                if ( workspace )
                {
                    workspace.steps = steps;
                }

                return [
                    200,
                    workspace,
                ];
            });
    }
}
