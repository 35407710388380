/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : '',
        link : '/dashboard'
    },
    {
        id   : 'appointments',
        title: 'Appointments',
        type : 'basic',
        icon : '',
        link : '/appointments'
    },
    {
        id   : 'exams',
        title: 'Exams',
        type : 'basic',
        icon : '',
        link : '/exams'
    },
    {
        id   : 'support',
        title: 'Support',
        type : 'basic',
        icon : '',
        link : '/support'
    },
    {
        id   : 'billing',
        title: 'Billing',
        type : 'basic',
        icon : '',
        link : '/billing'
    }
];
